import { useState, useContext, useEffect } from 'react'
import { z } from 'zod'
import type { SubmitHandler } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { TextInput } from 'src/components/member/update/TextInput/TextInput'
import { CheckboxInput } from 'src/components/member/update/CheckboxInput'
import { Modal } from 'src/components/member/update/Modal'
import config from 'src/temp/constants/config'
import { validateCPF } from 'validations-br'
import { Header } from 'src/components/member/update/Header'
import axios from 'axios'
import { AccountContext } from 'src/components/account/context'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { Loading } from 'src/components/member/update/Loading'
import { useLocation } from '@reach/router'
import {
  deleteCookie,
  getCookie,
  getDomain,
  calculateExpirationDate,
} from 'src/utils/getCookie'

const createUserFormSchema = z.object({
  firstName: z.string().min(1, 'Por favor, insira seu nome'),
  lastName: z.string().min(1, 'Por favor, insira seu sobrenome'),
  cpf: z
    .string()
    .min(1, 'Por favor, insira seu CPF')
    .min(11, { message: 'CPF incompleto' })
    .refine((value) => validateCPF(value), { message: 'CPF inválido' }),
  phone: z
    .string()
    .min(1, 'Por favor, insira seu celular')
    .min(14, { message: 'Número incompleto' }),
  terms: z.literal(true, {
    errorMap: () => ({ message: 'Você deve aceitar os termos e condições' }),
  }),
  newsletter: z.boolean().default(false),
})

type CreateUserFormData = z.infer<typeof createUserFormSchema>

const modalErrorCpf = {
  title: 'O CPF fornecido já está associado a outra conta.',
  textContent:
    'Para continuar, por favor, faça login com o e-mail que possui o CPF cadastrado.',
  buttonTitle: 'Fazer login',
  htmlTextBottom: `Esqueceu qual o e-mail do seu login? <br/> Entre em <a href="https://www.decathlon.com.br/atendimento" rel="noopener" target="_blank"> contato com o suporte </a>`,
}

export default function ProfileUpdate() {
  const { updateUserDecathlon } = useContext(AccountContext)
  const [loading, setLoading] = useState(false)
  const [errorCpfDuplicated, setErrorCpfDuplicated] = useState(false)
  const [returnUrl, setReturnUrl] = useState('')
  const [cookies, setCookies] = useState<
    Array<{ name: string; value: string }>
  >([])

  const { href } = useLocation()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateUserFormData>({
    resolver: zodResolver(createUserFormSchema),
    defaultValues: { terms: true, newsletter: false },
  })

  const onSubmit: SubmitHandler<CreateUserFormData> = async (data) => {
    setLoading(true)

    try {
      const authToken = cookies.find(
        (cookie) => cookie.name === 'memberAuthToken'
      )?.value

      await axios.post('/api/member/updateUser', {
        memberAuthToken: authToken,
        consentInput: {
          purposes: [
            {
              id: 'braziltcu-HhqPBTgG',
              enabled: true,
            },
            {
              id: 'mysports-RmzeVX4K',
              enabled: data.newsletter,
            },
          ],
        },
        claimsInput: {
          cpf: data.cpf,
          given_name: data.firstName,
          family_name: data.lastName,
          phone_number: data.phone,
        },
        cpfInput: { cpf: data.cpf },
      })

      await updateUserDecathlon()

      cookies.forEach((cookie) => {
        const expiration = calculateExpirationDate(
          cookie.name === 'memberAuthToken' ? 900 : 1800
        )

        document.cookie = `${cookie.name}=${
          cookie.value
        }; Expires=${expiration}; Path=/; Domain=${getDomain(href)}; Secure`
      })

      performVtexIDSession()

      // @TODO validar tratamento de eeros graphQL
      // if (errors?.length) return
    } catch (error) {
      const errorsType = error.response.data.response.errors ?? []

      const errorDuplicatedCpf = errorsType.find(
        ({ message }: { message: any }) => {
          const { http_status_code: httpStatusCode } = JSON.parse(message)

          return httpStatusCode === 409
        }
      )

      if (errorDuplicatedCpf) {
        setLoading(false)
        setErrorCpfDuplicated(true)

        throw new Error(`Erro cpf duplicado, ${data.cpf}`)
      }

      setLoading(false)
      alert('Erro interno... tente novamente')
      throw new Error(`Erro ao complementar dados do perfil`, error)
    }
  }

  function performVtexIDSession() {
    window.document.location.href = returnUrl
      ? decodeURIComponent(returnUrl)
      : '/'
  }

  function performRestartLogin() {
    window.location.href = config.member.restart_login_url
  }

  function saveCookiesInState() {
    const setedCookies = ['memberRefreshToken', 'memberAuthToken']

    setCookies(
      setedCookies.map((cookie) => {
        return { name: cookie, value: getCookie(cookie) }
      })
    )
  }

  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)

    setReturnUrl(urlParams.get('returnUrl') ?? '')
    saveCookiesInState()
  }, [])

  useEffect(() => {
    if (cookies.length) {
      cookies.forEach((cookie) => {
        deleteCookie(cookie.name)
      })
    }
  }, [cookies])

  return (
    <>
      <GatsbySeo />
      <section className="update-container font-inter h-[calc(100vh-86px)]">
        <Header />
        <article className="memberUpdate h-full">
          {loading && <Loading />}

          <div className="content h-full flex">
            <div className="image-wrapper flex-1 hidden md:block">
              <div
                aria-hidden="true"
                className="w-full h-full bg-top bg-no-repeat"
                style={{
                  backgroundImage:
                    'url("https://decathlonstoreapp.vteximg.com.br/arquivos/side_picture-B36EUE8G.jpg")',
                  backgroundSize: 'auto 100%',
                }}
              />
            </div>

            <div className="action-container overflow-scroll px-5 md:px-0 flex justify-center flex-1">
              <div className="form-wrapper max-w-[422px] md:mt-10">
                <h1 className="font-inter text-[26px] mb-7 font-semibold">
                  Complete seu cadastro
                </h1>
                <form
                  className="form flex flex-col gap-4 "
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <TextInput
                    mask=""
                    type="text"
                    label="Nome"
                    placeholder="Primeiro nome"
                    name="firstName"
                    errors={errors.firstName}
                    register={register}
                  />

                  <TextInput
                    mask=""
                    type="text"
                    label="Sobrenome"
                    placeholder="Sobrenome"
                    name="lastName"
                    errors={errors.lastName}
                    register={register}
                  />

                  <div className="flex gap-4">
                    <TextInput
                      mask="999.999.999-99"
                      type="text"
                      label="CPF"
                      placeholder="___.___.___-__"
                      errors={errors.cpf}
                      name="cpf"
                      register={register}
                    />

                    <TextInput
                      mask="+55 (99) 99999-9999"
                      type="tel"
                      label="Celular"
                      placeholder="+55 (__) _____-____"
                      errors={errors.phone}
                      name="phone"
                      register={register}
                    />
                  </div>

                  <div>
                    <CheckboxInput
                      name="newsletter"
                      register={register}
                      style={{ marginTop: '8px' }}
                      defaultChecked
                    >
                      Receber dicas e descontos por e-mail
                    </CheckboxInput>
                  </div>

                  <button
                    className="w-full mw-[422px] h-[48px] mt-4 font-inter text-sm font-medium text-white bg-restructure-background-blue hover:bg-restructure-background-action-1-hover"
                    type="submit"
                  >
                    CRIAR CONTA
                  </button>

                  <p className="text-xs font-inter font-normal text-darkGray text-center">
                    Ao finalizar o seu cadastro, você aceita nossa{' '}
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://www.decathlon.com.br/servicos/politica-de-privacidade/"
                      className="underline"
                    >
                      política de privacidade
                    </a>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </article>
        {errorCpfDuplicated && (
          <Modal
            {...modalErrorCpf}
            buttonAction={() => performRestartLogin()}
          />
        )}
      </section>
    </>
  )
}
