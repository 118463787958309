const getHost = () =>
  window.location.host.includes('vtex.app')
    ? 'decathlonstore.vtex.app'
    : window.location.host

const domain = typeof window !== `undefined` ? getHost() : ''
// const domain = 'secure.dktlab.com.br'
// const domain = 'secure.decathlon.com.br'

const memberOptions = {
  prod: {
    env: 'prod',
    base: 'https://api-global.decathlon.net/',
    base_url: 'https://api-global.decathlon.net/connect/oauth/authorize',
    client_id: 'efa494a3-c3e3-43ee-823f-b9a044509428',
    client_name: 'ecommerceapi_br',
    locale: 'pt_BR',
    redirect_logout: `https://www.decathlon.com.br`,
    redirect_url: `${
      domain.includes('localhost') ? 'http' : 'https'
    }://${domain}/member/authorize`,
    restart_login_url: `https://${domain}/member/logout?returnUrl=restartLogin`,
    response_type: 'code',
    state: 'newLogin',
    scope: 'profile:write%20openid%20optins:write%20email%20sports:write',
  },
  dev: {
    env: 'preprod',
    base: 'https://api-global.preprod.decathlon.net/',
    base_url:
      'https://api-global.preprod.decathlon.net/connect/oauth/authorize',
    client_id: '8d2ab87a-8af9-4539-b915-28d4a976f65d',
    locale: 'pt_BR',
    redirect_logout: `https://www.decathlon.com.br`,
    redirect_url: `https://${domain}/member/authorize`,
    response_type: 'code',
    restart_login_url: `https://${domain}/member/logout?returnUrl=restartLogin`,
    state: 'newLogin',
    scope: 'profile:write%20openid%20profile%20optins:write%20email',
  },
}

export default {
  // member: memberOptions.prod
  member: memberOptions.prod,
}
